var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.questions,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title || 'Нет названия')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","small":"","color":"blue","to":{
            name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTION,
            params : {
              topicId: _vm.topicId,
              testId: _vm.testId,
              questionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр вопроса")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","depressed":"","small":"","color":"blue","to":{
            name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWERS,
            params : {
              topicId: _vm.topicId,
              testId: _vm.testId,
              questionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-list ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр ответов")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","depressed":"","small":"","color":"warning","to":{
            name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTION_EDIT,
            params : {
              topicId: _vm.topicId,
              testId: _vm.testId,
              questionId : item.id
            }
          }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование вопроса")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({attrs:{"icon":"","depressed":"","small":"","color":"red"},on:{"click":function($event){return _vm.$emit('remove:question', item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление вопроса")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }