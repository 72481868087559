<template>
  <TTView>
    <VDialog
      v-model="confirmDialog"
      max-width="500px"
      @click:outside="handleCancel"
    >
      <VCard>
        <VCardTitle class="headline">
          Удалить вопрос
        </VCardTitle>

        <VCardText>
          Вопрос <span class="red--text">{{ question.id }}</span> будет удален! <br>
          Действительно хотите удалить вопрос?
        </VCardText>

        <VCardActions>
          <VSpacer />

          <VBtn
            text
            @click="handleCancel"
          >
            Нет
          </VBtn>

          <VBtn
            color="red darken-1"
            text
            @click="removeQuestion"
          >
            Да
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <VRow>
      <VCol cols="12">
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>
              Вопросы
            </VToolbarTitle>

            <VSpacer />

            <VBtn
              depressed
              color="primary"
              :to="{
                name : Names.R_TALENT_TEST_TOPIC_QUESTION_CREATE,
                params : {
                  testId,
                  topicId
                }
              }"
            >
              <VIcon class="mr-2">
                fal fa-plus
              </VIcon>
              <span>Создать вопрос</span>
            </VBtn>
          </VToolbar>
          <VCardText>
            <QuestionTable
              :test-id="testId"
              :topic-id="topicId"
              :questions="questions"
              :loading="loadingQuestions"
              @remove:question="deleteQuestion"
            />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import QuestionTable from '../../../../../components/talent/test/topics/questions/QuestionTable.vue';

export default {
  name: 'QuestionList',
  components: { QuestionTable },
  inject: ['Names'],
  data() {
    return {
      questions: [],
      loadingQuestions: false,
      question: {},
      confirmDialog: false,
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },
  async created() {
    await this.getQuestions(this.testId, this.topicId);
  },
  methods: {
    async getQuestions(testId, topicId) {
      this.loadingQuestions = true;
      try {
        this.questions = (await this.$di.api.Talent.questionsGet({ testId, topicId })) || [];
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.loadingQuestions = false;
      }
    },
    async removeQuestion() {
      try {
        const {
          question: { id: questionId },
          testId,
        } = this;
        const data = { testId, questionId };

        await this.$di.api.Talent.questionDelete(data);
        await this.getQuestions(this.testId, this.topicId);

        this.resetQuestionEdit();

        this.$di.notify.snackSuccess('Вопрос успешно удалён');
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    resetQuestionEdit() {
      this.confirmDialog = false;
      this.question = {};
    },

    handleCancel() {
      this.resetQuestionEdit();
    },

    deleteQuestion(item) {
      this.confirmDialog = true;
      this.question = { ...item };
    },
  },
};
</script>
